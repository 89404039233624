import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '../Style/footer.css'; // Ensure this CSS file is available
import { images } from "../Library/images";
import { Box, Container, Grid, Typography, Input, Button } from '@mui/material';
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Box component="footer" className="footer-section">
      <Container>
        <Box className="footer-cta pt-5 pb-5">
          <Grid container spacing={3}>
            <Grid item xl={4} md={4} mb={3}>
              <Box className="single-cta">
                <i className="fas fa-map-marker-alt"></i>
                <Box className="cta-text">
                  <Typography variant="h4">United Arab Emirates</Typography>
                  <Typography>Dubai | Jael Selly.</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={4} md={4} mb={3}>
              <Box className="single-cta">
                <i className="fas fa-phone"></i>
                <Box className="cta-text">
                  <Typography variant="h4">Call us</Typography>
                  <Typography>+971 58-538-3748</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={4} md={4} mb={3}>
              <Box className="single-cta">
                <i className="far fa-envelope-open"></i>
                <Box className="cta-text">
                  <Typography variant="h4">Mail us</Typography>
                  <Typography>jael@luxodysseysafaris.com</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="footer-cta pt-5 pb-5">
          <Grid container spacing={3}>
            <Grid item xl={4} md={4} mb={3}>
              <Box className="single-cta">
                <i className="fas fa-map-marker-alt"></i>
                <Box className="cta-text">
                  <Typography variant="h4">United States</Typography>
                  <Typography>New York | Joyce Olunga.</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={4} md={4} mb={3}>
              <Box className="single-cta">
                <i className="fas fa-phone"></i>
                <Box className="cta-text">
                  <Typography variant="h4">Call us</Typography>
                  <Typography>+1 347-366-0718</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={4} md={4} mb={3}>
              <Box className="single-cta">
                <i className="far fa-envelope-open"></i>
                <Box className="cta-text">
                  <Typography variant="h4">Mail us</Typography>
                  <Typography>joyce@luxodysseysafaris.com</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="footer-cta pt-5 pb-5">
          <Grid container spacing={3}>
            <Grid item xl={4} md={4} mb={3}>
              <Box className="single-cta">
                <i className="fas fa-map-marker-alt"></i>
                <Box className="cta-text">
                  <Typography variant="h4">Nairobi-Kenya</Typography>
                  <Typography>Sultan Estates Suite B9 | Karen.</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={4} md={4} mb={3}>
              <Box className="single-cta">
                <i className="fas fa-phone"></i>
                <Box className="cta-text">
                  <Typography variant="h4">Call us</Typography>
                  <Typography>+254 734-338-794</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={4} md={4} mb={3}>
              <Box className="single-cta">
                <i className="far fa-envelope-open"></i>
                <Box className="cta-text">
                  <Typography variant="h4">Mail us</Typography>
                  <Typography>info@luxodysseysafaris.com</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="footer-content pt-5 pb-5">
          <Grid container spacing={3}>
            <Grid item xl={4} lg={4} mb={5}>
              <Box className="footer-widget">
                <Box className="footer-logo">
                  <a href="index.html">
                    <img src={images.logo} className="img-fluid" alt="logo" />
                  </a>
                </Box>
                <Typography className="footer-text">
                  Each golfer is unique, and so are our tours.
                  From relaxing rounds with spa indulgences to championship challenges,
                  our expert team tailors every detail to your preferences.
                  Relax, and let us handle the rest while you perfect your swing.
                  <br />

                  
                  <br />
                </Typography>

                <Box className="footer-social-icon">
                  <Typography>Follow us</Typography>
                  <a href="https://www.instagram.com/luxodysseygolfsafaris/" target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-instagram instagram-bg"></i>
                  </a>
                  <a href="https://twitter.com/LuxOdysafaris" target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-twitter twitter-bg"></i>
                  </a>
                  <a href="https://www.facebook.com/profile.php?id=61560546425665" target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-facebook-f facebook-bg"></i>
                  </a>
                  <a href="https://www.linkedin.com/company/lux-odyssey-safaris/" target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-linkedin linkedin-bg"></i>
                  </a>
              </Box>

              </Box>
            </Grid>

            <Grid item xl={4} lg={4} md={6} mb={3}>
            <Box className="footer-widget">
              <Box className="footer-widget-heading" variant="h3">
                Useful Links
              </Box>
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/contact">Contact</Link></li>
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/services">Our Services</Link></li>
              </ul>
            </Box>
          </Grid>

            <Grid item xl={4} lg={4} md={6} mb={5}>
              <Box className="footer-widget">
                <Box className="footer-widget-heading" variant="h1">Subscribe</Box>
                <Typography className="footer-text mb-3">
                  Don’t miss to subscribe to our new feeds, kindly fill the form below.
                </Typography>
                <Box className="subscribe-form">
                  <form action="#">
                    <Input type="text" placeholder="Email Address" fullWidth />
                    <Button>
                      <i className="fab fa-telegram-plane"></i>
                    </Button>
                  </form>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Box className="copyright-area">
        <Container>
          <Grid container spacing={3}>
            <Grid item xl={6} lg={6} textAlign={{ xs: 'center', lg: 'left' }}>
              <Box className="copyright-text">
                <Typography>
                  Copyright &copy; 2025, All Right Reserved{' '}
                  <a href="https://github.com/Caleb-Muinde83">LuxOdyssey Safaris</a>
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={6} lg={6} display={{ xs: 'none', lg: 'block' }} textAlign="right">
            <Box className="footer-menu">
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/terms">Terms</Link></li>
                <li><Link to="/privacy">Privacy</Link></li>
                <li><Link to="/policy">Policy</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
            </Box>
          </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
