import React from 'react';
import '../Style/Team.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules'; // Import Swiper modules
import dan from '../images/Team/Dan.jpg'; // Import the partner logos
import lydia from '../images/Team/Lydia.jpg';
import ann from '../images/Team/Ann.jpg'; // Import the partner logos
import amina from '../images/Team/Amina.jpg';
import lekutas from '../images/Team/Lekutas.jpg'; // Import the partner logos
import caleb from '../images/Team/Caleb.jpg';
import jael from '../images/Team/Jael.jpg'; // Import the partner logos
import joyce from '../images/Team/Joyce.jpg';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const Team = () => {
    return (
        <div className="responsive-container-block outer-container">
            <div className="responsive-container-block inner-container">
                <p className="text-blk section-head-text">Our Team</p>
                <p className="text-blk section-subhead-text">
                    LuxOdyssey Safaris Ltd was conceptualized in 2020 and duly registered in2024, a
                    one of a kind new entrant Travel Agency revolutionizing the Tourism scene in
                    Kenya and East Africa by incorporating the traditional Safaris with the current
                    Travel vibes and offering the Africa Tourism scene from lenses of the modern
                    traveler. The Lux’O vibrant, adventurous and amazing team Captained and led by
                    Dan &amp; Lydia Matere founders and owners, welcomes you to an exhilarating
                    adventure of a lifetime because at LuxOdyssey Safaris no two experiences are the
                    same.
                </p>
                <p className="text-blk section-head-text">Expert Team</p>
                <div className="responsive-container-block">
                    <Swiper
                        modules={[Pagination]} // Pass the modules here
                        slidesPerView={3}
                        spaceBetween={30}
                        pagination={{ clickable: true }}
                        breakpoints={{
                            200: { slidesPerView: 1, spaceBetween: 20 },
                            750: { slidesPerView: 1, spaceBetween: 30 },
                            1024: { slidesPerView: 1.5, spaceBetween: 30 },
                            1100: { slidesPerView: 2, spaceBetween: 30 },
                        }}
                        className="team-swiper"
                    >
                        <SwiperSlide>
                            <div className="card">
                                <div className="img-wrapper">
                                    <img src={dan} alt="Dan" />
                                </div>
                                <div className="card-content">
                                    <p className="text-blk name">Dan G Matere</p>
                                    <p className="text-blk position">Chief Executive Officer</p>
                                    <p className="text-blk testimonial">
                                        Dan is an avid Golfer whose love for the game and his free spirit of adventure
                                        coupled with Business background and Entrepreneurial skills led him to conceive
                                        the idea of harmonizing all these experiences together to be able to share them
                                        with like-minded adventurists while having fun, travelling, appreciating nature
                                        doing what we love most enjoying our game together while expanding our social
                                        and Business Networks.
                                    </p>
                                    <div className="contact-info">
                                        <div>
                                            <i className="fas fa-envelope"></i>
                                        <span>dan@luxodysseysafaris.com</span>
                                    </div>
                                    <div>
                                        <i className="fas fa-phone"></i>
                                        <span>+254 722 298457</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card">
                                <div className="img-wrapper">
                                    <img src={lydia} alt="Lydia" />
                                </div>
                                <div className="card-content">
                                    <p className="text-blk name">Lydia Kimere-Matere</p>
                                    <p className="text-blk position">Managing Director and Lead Consultant</p>
                                    <p className="text-blk testimonial">
                                        Lydia born in the plains of Laikipia in Kenya grew up surrounded by wildlife and
                                        her love of nature is non negotiable. With a background in hospitality, her love for
                                        the outdoors borne the idea of starting a travel agency that would be an avenue
                                        of sharing and showcasing her world to the rest of the world. This she does with
                                        the utmost respect of nature and off course with the high standards and
                                        experiences gained from working with top hospitality entities bringing class,
                                        nature vibes and luxury.
                                    </p>
                                    <div className="contact-info">
                                        <div>
                                            <i className="fas fa-envelope"></i>
                                        <span>lydia@luxodysseysafaris.com</span>
                                    </div>
                                    <div>
                                        <i className="fas fa-phone"></i>
                                        <span>+254 723 542038</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card">
                                <div className="img-wrapper">
                                    <img src={ann} alt="Ann" />
                                </div>
                                <div className="card-content">
                                    <p className="text-blk name">Ann Wangeci</p>
                                    <p className="text-blk position">Travel Consultant and Business Manager</p>
                                    <p className="text-blk testimonial">
                                        Ann is a free spirit, never a dull moment in her life with an infectious persona to
                                        those around her. Having worked in the Hospitality industry in different levels she
                                        brings immense experiences in client handling, as a Business manager her team
                                        effort skills are impressive by harmonizing the team and overseeing the Business
                                        operations, clients are guaranteed of the very best that Lux’O has to offer leaving
                                        memorable experiences that only one can dream of.
                                    </p>
                                    <div className="contact-info">
                                        <div>
                                            <i className="fas fa-envelope"></i>
                                        <span>ann@luxodysseysafaris.com</span>
                                    </div>
                                    <div>
                                        <i className="fas fa-phone"></i>
                                        <span>+254 725 981582</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card">
                                <div className="img-wrapper">
                                    <img src={amina} alt="Amina" />
                                </div>
                                <div className="card-content">
                                    <p className="text-blk name">Amina Hamisi</p>
                                    <p className="text-blk position">Travel Leader & Hospitality Manager</p>
                                    <p className="text-blk testimonial">
                                        Amina’s diverse background is admirable a happy soul whose joyful nature is
                                        admirable. Her knack for details is top notch. With over 10 years in Tour guiding in
                                        and out of the East African Bush and its coastal line she has risen through the
                                        ranks to being the Leader Travel guide while impacting and sharing the skills with
                                        her team in offering our clients the best concierge experiences be it in the African
                                        bush,Flying over the African plains,diving with the Dolphins at the coast or dining
                                        at 5 Star establishments, her team has got you.
                                    </p>
                                    <div className="contact-info">
                                        <div>
                                            <i className="fas fa-envelope"></i>
                                        <span>amina@luxodysseysafaris.com</span>
                                    </div>
                                    <div>
                                        <i className="fas fa-phone"></i>
                                        <span>+254 718 210687</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card">
                                <div className="img-wrapper">
                                    <img src={lekutas} alt="Lekutas" />
                                </div>
                                <div className="card-content">
                                    <p className="text-blk name">Andrew Lekutas</p>
                                    <p className="text-blk position">Tour Guide and Operations Manager</p>
                                    <p className="text-blk testimonial">
                                        Andrew born in the Great Rift Valley in the Northern part of Kenya from the
                                        Samburu community is a walking encyclopedia. Having spent all his young life
                                        herding and interacting with animals in the wild he epitomizes the full sense of a
                                        warrior and adventurer. With over 20 years’ experience in traversing East Africa
                                        as a tour guide he brings onboard rich experiences, there is no nook that he
                                        together with his team won’t show you.
                                    </p>
                                    <div className="contact-info">
                                        <div>
                                            <i className="fas fa-envelope"></i>
                                        <span>lekutas@luxodysseysafaris.com</span>
                                    </div>
                                    <div>
                                        <i className="fas fa-phone"></i>
                                        <span>+254 799 634435</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card">
                                <div className="img-wrapper">
                                    <img src={caleb} alt="Caleb Muinde" />
                                </div>
                                <div className="card-content">
                                    <p className="text-blk name">Caleb Muinde</p>
                                    <p className="text-blk position">Digital Marketing and IT Manager</p>
                                    <p className="text-blk testimonial">
                                        Caleb, young at heart and body, is our IT head leading a team of vibrant individuals 
                                        who know no boundaries when it comes to creativity. Always designing and hunting new 
                                        destinations, he and his team deliver new, unexplored destinations to our consultancy team
                                        to curate bespoke destinations and experiences. With the information age taking new forms
                                        and shapes every day, we at Lux’O assure our clients that no new hotspots or destinations
                                        are out of our reach. Through constant communication and outreach to our clients, we create a 
                                        perfect communication avenue for the whole LuxOdyssey family—you and us.
                                    </p>
                                    <div className="contact-info">
                                        <div>
                                            <i className="fas fa-envelope"></i>
                                        <span>caleb@luxodysseysafaris.com</span>
                                    </div>
                                    <div>
                                        <i className="fas fa-phone"></i>
                                        <span>+254 794 501179</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card">
                                <div className="img-wrapper">
                                    <img src={jael} alt="Jael" />
                                </div>
                                <div className="card-content">
                                    <p className="text-blk name">Jael Wakhungu</p>
                                    <p className="text-blk position">Travel Consultant and General Manager – Dubai</p>
                                    <p className="text-blk testimonial">
                                        Jael is our GM in UAE and by extension the middle East. She brings a trove of
                                        experiences and market knowledge. Having worked in the hospitality industry in 2
                                        continents and currently based in Dubai she well versed with multiple cultures
                                        through her interactions , its all about class and the very best luxury can give to our clients while maintaining the highest standards.
                                        From Africa, Asia, Europe to America Jael and her team network has you covered.
                                    </p>
                                    <div className="contact-info">
                                        <div>
                                            <i className="fas fa-envelope"></i>
                                        <span>jael@luxodysseysafaris.com</span>
                                    </div>
                                    <div>
                                        <i className="fas fa-phone"></i>
                                        <span>+971 58 538 3748</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card">
                                <div className="img-wrapper">
                                    <img src={joyce} alt="Joyce Ollunga" />
                                </div>
                                <div className="card-content">
                                    <p className="text-blk name">Joyce Ollunga</p>
                                    <p className="text-blk position">Partner and Travel Consultant</p>
                                    <p className="text-blk testimonial">
                                        Joyce is our lead partner. Born and raised in Kenya she has a degree and
                                        background in Business and Finance from United Kingdom currently Mastering in
                                        The United States where she is a citizen. She brings immense wealth in Business
                                        networking, looking for new opportunities and executing partnerships in her
                                        wake. Its not all work and no play for Joyce a Golf addict her love for the game
                                        transcends all boundaries, in boardrooms to exotic destination courses, every
                                        moment is game and Business time. Her mantra is Trust the Networks. All our
                                        clients get to have Business Networking dinners and cocktails on their last Safari
                                        day organized by LuxOdyssey Safaris Teams on destinations with local Golfers and
                                        Business community.
                                    </p>
                                    <div className="contact-info">
                                        <div>
                                            <i className="fas fa-envelope"></i>
                                        <span>Joyce@luxodysseysafaris.com</span>
                                    </div>
                                    <div>
                                        <i className="fas fa-phone"></i>
                                        <span>+1 (347) 366-0718</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        {/* Add more SwiperSlide components here */}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default Team;
