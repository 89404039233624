import React from 'react';
import '../Style/ContactHeader.css'; // Importing CSS

const Header = () => {
    return (
        <header className="top-header">
            <div className="container">
                <div className="top-header-left">
                    <i className="fas fa-phone"></i> +254 734 338 794
                </div>
                <div className="top-header-right">
                    <a href="https://www.facebook.com/profile.php?id=61560546425665" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="https://twitter.com/LuxOdysafaris" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a href="https://www.instagram.com/luxodysseygolfsafaris/" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a href="https://www.linkedin.com/company/lux-odyssey-safaris/" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-linkedin"></i>
                    </a>
                </div>
            </div>
        </header>
    );
};

export default Header;
